<template>
  <div class="assigned-input">
    <person-select v-if="mounted" class="es-simple" v-model="assigned" />
    <div v-if="assigned" @click="unassign" class="unassign"><a>Desatribuir</a></div>
    <div v-else class="unassign"><a @click="assign">Atribuir a mim</a></div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "ResponsavelEdit",
  data () {
    return {
      assigned: null,
      bind: false,
      mounted: true
    }
  },
  mounted() {
    this.assigned = this.task.assigned
    this.$nextTick(() => {
      this.bind = true
    })
  },
  watch: {
    assigned (v) {
      this.bind && this.change(v)
    }
  },
  methods: {
    change (v) {
      const old = this.task.assigned
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Alterar responsável da tarefa para ' + v.name + '?',
        ok: 'Alterar responsável',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(v)
      })
          .catch(() => {
            this.mounted = false
            this.bind = false
            this.assigned = old
            this.$nextTick(() => {
              this.mounted = true
              this.bind = true
            })
          })
    },
    confirmUpdate (person) {
      const old = this.task.assigned
      this.loading = true
      updateTask({
        id: this.task.id,
        assigned: person ? person.id : null,
        notify: 'assignedTask'
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', person)
          })
          .catch(error => {
            this.loading = false
            this.task.assigned = old
            this.alertApiError(error)
          })
      if (!person) {
        this.mounted = false
        this.bind = false
        this.$nextTick(() => {
          this.assigned = null
          this.$nextTick(() => {
            this.mounted = true
          })
        })
      }
      this.task.assigned = person
    },
    unassign () {
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Remover responsável para esta tarefa?',
        ok: 'Alterar responsável',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(null)
      })
          .catch(() => {})
    },
    assign () {
      this.$uloc.dialog({
        title: 'Confirmar alteração de Responsável',
        message: 'Confirmar atribuição desta tarefa à você?',
        ok: 'Atrubuir a mim',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        const person = {
          id: this.$uloc.auth.session.user.person,
          photo: this.$uloc.auth.session.user.photo,
          name: this.$uloc.auth.session.user.name,
        }
        this.mounted = false
        this.bind = false
        this.$nextTick(() => {
          this.assigned = person
          this.$nextTick(() => {
            this.mounted = true
            this.bind = true
          })
        })
        this.confirmUpdate(person)
      })
          .catch(() => {})
    }
  },
  components: {PersonSelect},
  mixins: [TaskMixin]
}
</script>
