<template>
  <div class="stask-description"
       :class="classes"
       @click="edit"
  >
    <div class="lbl">Descrição</div>
    <div class="dvalue" v-html="task.text" v-if="!editing"></div>
    <div class="task-editor" v-else>
      <s-l-editor
          ref="editor"
          v-model="task.text"
          placeholder="Descreva em detalhes o pedido..."
          :show-toolbar-on-focus="true"
          :close-click-outside="false"
          autofocus
          @focus="() => { textEditing = true }"
      />
      <div class="act-btns">
        <u-btn @click="save" :disable="loading" no-caps label="Salvar" color="primary" class="" />
        <u-btn @click.stop.prevent="editing = false" :loading="loading" no-caps label="Cancelar" color="white" flat class="text-black m-l-sm" />
      </div>
    </div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import SLEditor from "components/layout/components/Editor"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "TaskDescription",
  components: {SLEditor},
  data() {
    return {
      editing: false,
      textEditing: false,
      loading: false
    }
  },
  computed: {
    classes() {
      const css = []
      this.editing && css.push('editing')
      this.emptyError && css.push('emptyError')
      return css
    }
  },
  methods: {
    edit () {
      if (this.editing) return
      this.editing = true
      this.$nextTick(() => {
        this.$refs.editor.focusEnd()
      })
    },
    save () {
      this.loading = true
      const text = this.$refs.editor.htmlContent()
      updateTask({
        id: this.task.id,
        text: text,
        notify: 'updateDescription'
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.task.text = text
            this.$emit('update', text)
            this.editing = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  mixins: [TaskMixin]
}
</script>
