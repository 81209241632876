<template>
  <u-btn class="task-status-btn btn-t" no-caps flat :class="btnClasses" :style="btnStyle">
    <div><span>{{status}}</span> <i class="fa fa-chevron-down m-l-sm font-10" /> </div>
    <u-popover ref="popup" class="window-context-menu menu-profile min" anchor="bottom left" self="top left">
      <ul @click="click">
        <li v-for="s in listActives" :key="s.id">
          <a @click="confirmaStatus(s)" class="menuItem">
            <div class="stask-status-a" :style="{color: s.color}">
              <span>{{s.name}}</span>
              <div class="bg-helper" :class="{'bg-exact': !!s.colorBg}" :style="{backgroundColor: s.colorBg || s.color}"></div>
            </div>
          </a>
        </li>
<!--        <li class="diviser"></li>-->
      </ul>
    </u-popover>
  </u-btn>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import {listStatus, updateTask} from "@/domain/tarefas/services"
import {UPopover} from "uloc-vue"

export default {
  name: "TaskStatusBtn",
  data () {
    return {
      loading: false,
      list: []
    }
  },
  mixins: [TaskMixin],
  components: {
    UPopover
  },
  mounted () {
    this.load()
  },
  computed: {
    listActives () {
      return this.list.filter(s => s.id !== this.task.status.id)
    },
    status () {
      return this.task.status.name
    },
    btnClasses () {
      const css = []
      css.push(`stask-status-${this.task.status.code}`)
      css.push(`stask-status-type-${this.task.status.type}`)
      css.push(`stask-status-internal-${this.task.internalStatus}`)
      return css
    },
    btnStyle () {
      const style = []

      if (this.task.status.colorBg) {
        style.push({
          backgroundColor: this.task.status.colorBg
        })
        this.task.status.color && style.push({
          color: this.task.status.color
        })
      } else {
          this.task.status.colorInverse && style.push({
            color: this.task.status.colorInverse
          })
          this.task.status.color && style.push({
            backgroundColor: this.task.status.color
          })
      }
      return style
    }
  },
  methods: {
    load () {
      listStatus (100, 1, 'active=1')
          .then(response => {
            this.list = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    confirmaStatus (status) {
      this.$uloc.dialog({
        title: 'Confirmar novo status',
        message: 'Alterar status da tarefa para ' + status.name + '?',
        ok: 'Alterar status',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.alteraStatus(status)
      })
        .catch(() => {})
    },
    alteraStatus (status) {
      const oldStatus = this.task.status
      this.loading = true
      updateTask({
        id: this.task.id,
        status: status.id,
        notify: 'updateStatus'
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.task.queuePosition = response.data.queuePosition
            this.$emit('update', status)
          })
          .catch(error => {
            this.loading = false
            this.task.status = oldStatus
            this.alertApiError(error)
          })
      this.task.status = status
    },
    closeMenu(e) {
      this.$refs.popup.hide()
    },
    click(e) {
      this.closeMenu(e)
      this.$emit('click', e)
    }
  }
}
</script>
