<template>
  <div class="stask-name-input" :class="classes">
    <h1 ref="input"
        @focusin="focusin"
        @focusout="focusout"
        contenteditable="true"
        @keydown="handleKey"
        @paste="handlePaste"
    >{{ task.description }}</h1>
    <i class="alertIcon fa fa-exclamation-triangle" />
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "TaskNameInput",
  data() {
    return {
      focus: false,
      emptyError: false
    }
  },
  computed: {
    classes () {
      const css = []
      this.focus && css.push('focused')
      this.emptyError && css.push('emptyError')
      return css
    }
  },
  methods: {
    focusin () {
      this.focus = true
      this.emptyError = false
    },
    focusout () {
      const data = this.$refs.input.textContent
      if (data !== this.task.description) {
        console.log('Mudou descrição', data)
        updateTask({
          id: this.task.id,
          description: data
        }, 'PATCH')
            .then(response => {
              this.task.description = data
              this.$emit('update', data)
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }
      const test = String(data).replace(/\s/g, '');
      if (test === '') {
        this.emptyError = true
        return
      }
      this.focus = false
    },
    handleKey(e) {
      const metaKeyNotAllowList = [
        'ArrowUp', // move to top
        'ArrowDown', // move to bottom
        'ArrowLeft', // move to start of line
        'ArrowRight', // move to end of line
        'a', // select all
        'c', // copy
        'v', // paste
        'z', // undo
      ];

      const keyNotAllowList = [
        'Enter'
      ];

      if (keyNotAllowList.includes(e.key)) {
        e.preventDefault()
        return false
      }

      if (e.metaKey && !metaKeyNotAllowList.includes(e.key)) {
        e.preventDefault()
        return false; // Avoid command key shortcuts
      }
    },
    handlePaste (event) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, this.stripHtmlTags(text));
    },
    stripHtmlTags(html) {
      // Função para remover todas as tags HTML do texto
      const tmpDiv = document.createElement("div");
      tmpDiv.innerHTML = html;
      const text = tmpDiv.textContent || tmpDiv.innerText || "";
      const withoutTags = text.replace(/<[^>]+>/g, '');
      // Remove as newlines (quebra de linha) da string
      return withoutTags.replace(/\r?\n|\r/g, '');
    }
  },
  mixins: [TaskMixin]
}
</script>
