<template>
  <div v-if="task.author" class="stask-author">
    <div class="person-avatar-name">
      <div class="person-avatar avatar md inline">
        <img v-if="task.author.photo" width="25" class="" :src="task.author.photo"/>
        <div v-else class="img-fake">{{ firstLettersName(task.author.name) }}</div>
      </div>
      <span class="name"><strong>{{ task.author.name }}</strong> criou esta solicitação <!--via <strong>Painel</strong>--> em <span style="cursor: default">
            {{ task.createdAt.date|formatDate('dd/MM/yyyy HH:ii:ss') }}
          </span></span>
    </div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"

export default {
  name: "Author",
  mixins: [TaskMixin]
}
</script>
