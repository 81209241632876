import {differenceInHours, isToday, parse, parseISO} from "date-fns";

export default {
    data () {
        return {
            bind: false,
            model: null
        }
    },
    mounted() {
        this.reset()
    },
    watch: {
        model (v) {
           this.bind && this.change && this.change(v)
        }
    },
    methods: {
        parseDate(d) {
            if (!d) return
            if (d.date) {
                return d.date
            }
            let date
            try {
                if (String(d).length === 10 || String(d).length === 11) {
                    date = parse(d, 'dd/MM/yyyy', new Date())
                } else if (String(d).length >= 16) {
                    date = parse(d, 'dd/MM/yyyy HH:mm', new Date())
                } else {
                    date = null
                }
            } catch (e) {
                date = null
            }
            return date
        },
        compareDate(d) {
            return Math.floor(differenceInHours(parseISO(d.date || d), new Date()) / 24)
        },
        isToday(d) {
            return isToday(parseISO(d.date || d))
        },
        colorize(d) {
            if (d.date) {
                d = d.date
            }
            const compare = this.compareDate(d)
            if (compare === 0) {
                return 'deep-orange'
            } else if (compare < 0) {
                return 'negative'
            } else {
                return 'positive'
            }
        },
        reset () {
            this.bind = false
            this.$nextTick(() => {
                this.edit = false
                this.model = null
                this.__reset && this.__reset()
                this.$nextTick(() => {
                    this.bind = true
                })
            })
        },
        cancel () {
            this.reset()
            this.__cancel && this.__cancel()
        }
    }
}
