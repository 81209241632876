<template>
  <div class="stask-activity"
       :class="classes"
  >
    <div class="lbl">Atividade</div>
    <ul class="act-menu">
      <li v-for="(s, key) in sections" :key="key">
        <a @click="changeTab(s)" :class="{active: activeTab === s.id}">{{s.name}}</a>
      </li>
    </ul>
    <div class="tab-content">
      <component v-bind:is="tabComponent" :task="task" :props="componentProps"></component>
    </div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import LoadingComments from "components/sltarefas/components/tarefa/tabs/comments-loading"

const All = () => ({
  component: import('./tabs/comments.vue'),
  loading: LoadingComments,
  error: LoadingComments,
  delay: 200,
  timeout: 5000
})
const Comments = () => ({
  component: import('./tabs/comments.vue'),
  loading: LoadingComments,
  error: LoadingComments,
  delay: 200,
  timeout: 5000
})
const Files = () => ({
  component: import('./tabs/comments.vue'),
  loading: LoadingComments,
  error: LoadingComments,
  delay: 200,
  timeout: 5000
})
const Modify = () => ({
  component: import('./tabs/comments.vue'),
  loading: LoadingComments,
  error: LoadingComments,
  delay: 200,
  timeout: 5000
})

export default {
  name: "TaskActivity",
  provide () {
    return {
      containerTab: this
    }
  },
  components: {},
  data() {
    return {
      activeTab: 'comments'
    }
  },
  computed: {
    tabComponent () {
      const components = {
        'all': All,
        'comments': Comments,
        'files': Files,
        'modify': Modify,
      }
      return components[this.activeTab]
    },
    componentProps () {
      const activeTab = this.sections.find(s => s.id === this.activeTab)
      return activeTab.props
    },
    classes() {
      const css = []
      return css
    },
    sections () {
      return [
        {id: 'all', name: 'Tudo', props: {filter: null}},
        {id: 'comments', name: 'Comentários', props: {filter: 'comment'}},
        {id: 'files', name: 'Arquivos', props: {filter: 'file'}},
        {id: 'modify', name: 'Alterações', props: {filter: 'modify'}},
        //{id: 'history', name: 'Histórico'},
        //{id: 'work', name: 'Registro de trabalho'},
      ]
    }
  },
  methods: {
    changeTab (tab) {
      this.activeTab = tab.id
    }
  },
  mixins: [TaskMixin]
}
</script>
