<script>
export default {
  name: 'PriorityIcon',
  props: ['priority'],
  data () {
    return {}
  },
  computed: {
    level () {
      return Number(this.priority.order)
    }
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {}
}
</script>

<template>
    <span>
        <u-icon v-if="level === 1" :title="priority.name" name="arrow-alt-down" type="fa" icon-style="solid" color="grey-5"></u-icon>
        <u-icon v-if="!level || level === 2" :title="priority.name" name="square" type="fa" icon-style="solid" color="grey"></u-icon>
        <u-icon v-if="level === 3" :title="priority.name" name="arrow-alt-up" type="fa" icon-style="solid" color="danger"></u-icon>
        <u-icon v-if="level >= 4" :title="priority.name" name="exclamation-triangle" type="fa" icon-style="solid" color="danger"></u-icon>
    </span>
</template>
