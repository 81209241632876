<template>
  <div @click="edit = true" class="hover-anchor cursor-pointer flex items-center">
    <span v-if="!edit">{{ value }}</span>
    <u-input @keydown="hoursKeydown" @change="hoursKeydown" @keydown.enter="defineHours" v-else v-model="fake" hide-underline autofocus class="app-input" />
    <span v-if="!edit" class="hover-action m-l-xs" style="font-size: 14px"><u-icon name="pen" type="fa" icon-style="regular"/></span>
  </div>
</template>

<script>
import {stopAndPrevent} from "uloc-vue/src/utils/event"
import {UInput} from 'uloc-vue'
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "HourInput",
  props: ['value'],
  mixins: [TaskMixin],
  components: {
    UInput
  },
  data () {
    return {
      edit: false,
      fake: this.value
    }
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    clickOutside(evt) {
      if (
          evt && evt.target &&
          (this.$el.contains(evt.target) || this.$el.parentNode.contains(evt.target))
      ) {
        return
      }
      this.edit && this.fake !== this.value && this.defineHours(evt)
      this.edit = false
      // this.hide(evt)
    },
    hoursKeydown (e, field) {
      let value = String(e.target.value)
      const key = String(e.key)
      if (key.length === 1) {
        value = value + key
      }
      // only digits
      const specialKeys = [];
      specialKeys.push(8);
      const keyCode = e.which ? e.which : e.keyCode
      if (
          (e.shiftKey || e.ctrlKey || e.metaKey) && String(e.key).toLowerCase() === 'a' ||
          (e.shiftKey || e.ctrlKey || e.metaKey) && String(e.key).toLowerCase() === 'x' ||
          // (e.shiftKey || e.ctrlKey || e.metaKey) && String(e.key).toLowerCase() === 'v' ||
          (e.shiftKey || e.ctrlKey || e.metaKey) && String(e.key).toLowerCase() === 'c'
      ) {
        return
      }
      const ret = ((keyCode >= 48 && keyCode <= 57) || specialKeys.indexOf(keyCode) !== -1);
      if (!ret && !/[hH:]/g.test(key)) {
        stopAndPrevent(e)
      }
      let separate = null
      const strs = ['h', 'H', ':']
      let counto = 0
      strs.map(str => {
        const regex = new RegExp(str, 'g')
        const c = (value.match(regex) || []).length;
        if (c) {
          if (!separate) {
            separate = str
          }
          counto = counto + c
        }
      })
      if (counto > 1) {
        stopAndPrevent(e)
      }
      if (String(e.target.value).length === 0 && key.length === 1 && /[hH:]/g.test(key)){
        e.target.value = '0' + e.target.value
      }
      let split = value.split(separate)
      if (split[0].length > 3) stopAndPrevent(e)
      if (typeof split[1] !== 'undefined') {
        if (split[1].length > 2) stopAndPrevent(e)
        if (Number(split[1].charAt(0)) > 5) stopAndPrevent(e)
      }
    },
    defineHours (e) {
      this.edit = false
      let value = this.fake
      if (!value) return
      let separate = value.replace(/\d/gi, '')
      console.log(separate)
      if (!separate) {
        value = String(value).padStart(2, '0') + ':00'
      } else {
        let parts = value.split(separate)
        if (parts.length === 2) {
          value = String(parts[0]).padStart(2, '0') + ':' + String(parts[1]).padEnd(2, '0')
        }
      }
      this.$uloc.dialog({
        title: 'Registrar estimativa de tempo',
        message: 'Definir estimativa de tempo para ' + String(value).replace(':', 'h') + '?',
        ok: 'Confirmar estimativa',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        updateTask({
          id: this.task.id,
          expectedHours: value
        }, 'PATCH')
            .then(response => {
              this.$emit('input', value)
              this.$emit('update', value)
              this.task.workedHours = response.data.workedHours
              this.task.progress = response.data.progress
            })
            .catch(error => {
              this.fake = this.task.expectedHours
              this.alertApiError(error)
            })
      }).catch(() => {
        this.fake = this.task.expectedHours
      })
    }
  }
}
</script>
