<script>
import {followTask, unfollowTask} from "@/domain/tarefas/services"
import {UTooltip} from "uloc-vue"

export default {
  name: 'follow-task',
  props: {
    task: {required: true},
    showLegend: {
      type: Boolean,
      default: true
    },
    showFollowers: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    me () {
      return this.$uloc.auth.session.user.person
    },
    isSeguindo() {
      if (!this.task.followers || !this.task.followers.length) {
        return false
      }
      return this.task.followers.find(s => s.person.id === Number(this.me))
    },
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {
    follow () {
      followTask(this.task)
          .then(response => {
            this.$emit('followersUpdate', response.data)
            this.task.followers = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    unfollow () {
      unfollowTask(this.task)
          .then(response => {
            this.$emit('followersUpdate', response.data)
            this.task.followers = response.data
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  },
  components: {
    UTooltip
  }
}
</script>

<template>
  <div class="stask-followers">
    <div class="followers" v-if="showFollowers">
      <div v-for="follower in task.followers" :key="follower.id">
        <div class="person-avatar avatar md inline">
          <u-tooltip class="min-tooltip" :offset="[4, 4]">{{follower.person.name}}</u-tooltip>
          <img v-if="follower.person.photo" width="25" class="" :src="follower.person.photo"/>
          <div v-else class="img-fake">{{ firstLettersName(follower.person.name) }}</div>
        </div>
      </div>
    </div>
    <a @click="() => isSeguindo ? unfollow() : follow()" :class="{active: active, 'link-black': !isSeguindo}">
      <u-icon :name="isSeguindo ? 'bell' : 'bell'" type="fa" class="icon-mt m-r-xs" :class="{'active': isSeguindo}">
        <template v-if="showLegend">
          <span v-if="!isSeguindo">Seguir</span>
          <span v-else>Seguindo</span>
        </template>
      </u-icon>
      <u-tooltip class="min-tooltip">Seguir e receber notificações</u-tooltip>
    </a>
  </div>
</template>
