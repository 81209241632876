<template>
  <div class="stask-attachments"
       :class="classes"
  >
    <div class="lbl">Arquivos anexos</div>
    <div>
      <task-attachments ref="attachments" :task="task" />
    </div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import TaskAttachments from "components/suporte/components/task/include/attachment/Attachments"

export default {
  name: "Anexos",
  components: {
    TaskAttachments
  },
  data() {
    return {
    }
  },
  computed: {
    classes() {
      const css = []
      return css
    }
  },
  methods: {
  },
  mixins: [TaskMixin]
}
</script>
