<template>
  <div class="assigned-input">
      <task-queue-select v-model="model" :project="task.project ? task.project.id : null" with-no-project class="es-simple only-text" />
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import TaskHelper from "components/suporte/components/task/include/TaskHelper"
import TaskZoneInput from "components/suporte/components/task/include/TaskZoneInput"
import {updateTask} from "@/domain/tarefas/services"
import TaskQueueSelect from "components/suporte/components/task/include/QueueSelect"

export default {
  name: "QueueEdit",
  mixins: [TaskZoneInput, TaskMixin, TaskHelper],
  methods: {
    __reset () {
      this.model = this.task.queue
    },
    change (v) {
      this.$uloc.dialog({
        title: 'Confirmar alteração da fila',
        message: !v ? 'Remover fila desta tarefa?' : 'Alterar fila da tarefa para ' + v.name + '?',
        ok: 'Alterar fila',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      })
          .then(() => {
            const old = this.task.queue
            this.loading = true
            updateTask({
              id: this.task.id,
              queue: v ? v.id : null
            }, 'PATCH')
                .then(response => {
                  this.loading = false
                  //this.reset()
                  this.task.queuePosition = response.data.queuePosition
                  this.$emit('update', v)
                })
                .catch(error => {
                  this.loading = false
                  this.task.queue = old
                  this.alertApiError(error)
                })
            this.task.queue = v
          })
          .catch(() => {
          })
    }
  },
  components: {TaskQueueSelect}
}
</script>

