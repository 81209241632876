<template>
  <div class="control">
    <u-btn @click="playPause" :disable="!task.id || taskContainer.isClosed" :color="!isWorking ? 'positive' : 'blue-grey-8'" :icon="!isWorking ? 'play' : 'pause'" icon-type="fa" push class="task-play-btn">
      <span class="m-l-sm m-t-xs">{{timer}}</span>
      <u-tooltip v-if="task.id && !taskContainer.isClosed" anchor="bottom middle" self="top middle" :offset="[5,5]">
        <span v-if="!isWorking">Pressione para começar a trabalhar nesta tarefa</span>
        <span v-else>Pressione para parar de trabalhar nesta tarefa</span>
      </u-tooltip>
    </u-btn>
  </div>
</template>

<script>
import {UTooltip, UBtn} from "uloc-vue"
import {work} from "@/domain/tarefas/services"
import {parseISO, differenceInSeconds, differenceInMinutes, differenceInHours} from "date-fns"
import TaskMixin from "components/suporte/components/task/include/TaskMixin"

export default {
  name: "TaskWork",
  mixins: [TaskMixin],
  data () {
    return {
      timer: '00:00:00'
    }
  },
  components: {
    UTooltip,
    UBtn
  },
  computed: {
    me () {
      return this.$uloc.auth.session.user.person
    },
    isWorking () {
      if (!this.task.works || !this.task.works.length || !this.task.participants || !this.task.participants.length) return false
      const participant = this.getParticipant(this.me)
      if (!participant) return false
      const work = this.getWorkOfParticipant(participant)
      return work && !work.stopTime
    }
  },
  mounted() {
    this.$timer = setInterval(() => {
      this.getTimer()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.$timer)
  },
  methods: {
    getParticipant (person) { // @TODO: Reaproveitar
      let participant = null
      this.task.participants.map(p => {
        if (Number(p.person.id) === Number(person)) {
          participant = p
        }
      })
      console.log('P', participant)
      return participant
    },
    getWorkOfParticipant (participant) { // @TODO: Reaproveitar
      let work = null
      this.task.works.map(w => {
        if (w.participant.id === participant.id && !w.stopTime) {
          work = w
        }
      })
      console.log('W', work)
      return work
    },
    playPause () {
      console.log(this.isWorking)
      !this.isWorking ? this.play() : this.pause()
    },
    play() {
      this.$uloc.dialog({
        title: 'Iniciar trabalho',
        message: `Confirmar início do trabalho nesta tarefa ?`,
        noCaps: true,
        ok: `Sim`,
        cancel: 'Não'
      })
          .then(() => {
            work(this.task, {action: 'start'})
                .then(response => {
                  console.log(response.data)
                  this.parseUpdate(response.data)
                  this.getTimer()
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    pause() {
      this.$uloc.dialog({
        title: 'Parar trabalho',
        message: `Confirmar pausa ou encerramento do trabalho nesta tarefa ?`,
        noCaps: true,
        ok: `Sim`,
        cancel: 'Não'
      })
          .then(() => {
            work(this.task, {action: 'stop'})
                .then(response => {
                  this.parseUpdate(response.data)
                  this.$uloc.notify({
                    color: 'black',
                    message: `Tempo de trabalho registrado com sucesso`,
                    position: 'bottom-left'
                  })
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    parseUpdate (task) {
      this.task.participants = task.participants
      this.task.works = task.works
      this.task.workedHours = task.workedHours
      this.task.startTime = task.startTime
      this.task.progress = task.progress
      this.$nextTick(() => {
        this.taskContainer.$emit('update')
      })
    },
    getTimer() {
      let timer = '00:00:00'
      if (!this.isWorking) {
        this.timer = timer
        return
      }
      const participant = this.getParticipant(this.me)
      if (!participant) return false
      const work = this.getWorkOfParticipant(participant)
      const startDate = parseISO(work.startTime.date)
      const seconds = differenceInSeconds(new Date(), startDate)
      const minutes = differenceInMinutes(new Date(), startDate)
      const hours = differenceInHours(new Date(), startDate)
      if (seconds < 60) {
        timer = '00:00:' + String(seconds).padStart(2, '0')
      } else if (minutes < 59) {
        const s = String(seconds % 60).padStart(2, '0')
        timer = '00:' + String(minutes).padStart(2, '0') + ':' + s
      } else {
        const m = String(minutes % 60).padStart(2, '0')
        const s = String(seconds % 60).padStart(2, '0')
        timer = String(hours).padStart(2, '0') + ':' + m + ':' + s
      }
      this.timer = timer
    }
  }
}
</script>
