export default {
    data() {
        return {
            edit: false
        }
    },
    mounted() {
        // document.body.addEventListener('click', this.clickOutside, true)
    },
    beforeDestroy() {
        // document.body.removeEventListener('click', this.clickOutside)
    },
    methods: {
        clickEdit () {
            this.edit = true
        },
        clickOutside(evt) {
            if (
                evt && evt.target &&
                (
                    this.$el.contains(evt.target) ||
                    (this.$el.parentNode && this.$el.parentNode.contains(evt.target))
                )
            ) {
                return
            }
            this.edit = false
        },
    }
}
