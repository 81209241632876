<template>
  <div class="flex items-center no-wrap">
    <u-progress class="progress-sgrp m-r"
                :percentage="task.progress"
                color="positive" height="6px" style="border-radius: 5px; max-width: 70%"></u-progress>
    {{ task.progress }}%
  </div>
</template>

<script>
import {UProgress} from "uloc-vue"
import TaskMixin from "components/suporte/components/task/include/TaskMixin"

export default {
  name: "TaskProgress",
  mixins: [TaskMixin],
  components: {
    UProgress
  }
}
</script>
