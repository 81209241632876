<template>
  <person-select class="es-simple" v-model="author" />
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import {updateTask} from "@/domain/tarefas/services"

export default {
  name: "AuthorEdit",
  data () {
    return {
      author: null,
      bind: false
    }
  },
  mounted() {
    this.author = this.task.author
    this.$nextTick(() => {
      this.bind = true
    })
  },
  watch: {
    author (v) {
      this.bind && this.change(v)
    }
  },
  methods: {
    change (v) {
      const old = this.task.author
      this.$uloc.dialog({
        title: 'Confirmar alteração de Relator ',
        message: 'Alterar relator da tarefa para ' + v.name + '?',
        ok: 'Alterar relator',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      }).then(() => {
        this.confirmUpdate(v)
      })
          .catch(() => {
            this.mounted = false
            this.bind = false
            this.author = old
            this.$nextTick(() => {
              this.mounted = true
              this.bind = true
            })
          })
    },
    confirmUpdate (person) {
      const old = this.task.author
      this.loading = true
      updateTask({
        id: this.task.id,
        author: person.id
      }, 'PATCH')
          .then(response => {
            this.loading = false
            this.$emit('update', person)
          })
          .catch(error => {
            this.loading = false
            this.task.author = old
            this.alertApiError(error)
          })
      this.task.author = person
    },
  },
  components: {PersonSelect},
  mixins: [TaskMixin]
}
</script>
