<template>
<div class="task-right-tab">
  <div class="header">
    <div class="lbl">{{title}}</div>
  </div>
  <div class="content"><slot /></div>
</div>
</template>

<script>
export default {
  name: "TaskRightTab",
  props: ['title']
}
</script>
